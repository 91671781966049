import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { PositionedSpinner } from "../../../../../../../../components/ui/PositionedSpinner";
import { SingleForm } from "../../../../../../../change/forms/singleFormModel/SingleForm_view";
import { BenefitDescriptionModel } from "./BenefitDescription_model";

interface BenefitDescriptionProps {
  model: BenefitDescriptionModel;
}

export const BenefitDescription: React.FC<BenefitDescriptionProps> = observer(({ model }) => {
  useEffect(() => {
    model.onMount();
  }, []);

  if (typeof model.benefit === "undefined") return;

  if (model.isLoading) return <PositionedSpinner />;

  return (
    <div className="container-fluid">
      <SingleForm model={model.formModel} />
    </div>
  );
});
